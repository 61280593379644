import React from 'react'
import style from './Item.module.scss';

const Item = (props) => {
    return (
        <>
            <div className={style.Item}>
                <div className={style.Image} style={{backgroundImage: `url(${props.image})`}}>
                </div>
                <div className={style.Content}>
                    <div>
                        <h2>
                            {props.title}
                        </h2>
                        <p>{props.content}</p>
                    </div>
                    <div className={style.slideUpBtn}>
                        <span>Bekijk item</span>
                        </div>
                </div>
            </div>
        </>
    )
}

export default Item;