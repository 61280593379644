import React, {useEffect} from 'react';
import {
    Link
  } from "react-router-dom";
import imageOne from '../../Assets/Images/1.jpg';
import imageTwo from '../../Assets/Images/2.jpg';
import imageThree from '../../Assets/Images/3.jpg';
import imageFour from '../../Assets/Images/4.jpg';
import Item from '../Item/Item';
import style from './Overview.module.scss';

const Menu = () => {
        
    useEffect(() => {
        window.scrollTo({top:0, behavior: 'smooth'})
      }, []);

    return (
        <>
            <div className={style.Container}>
                <div className={style.Row}>
                    <Link to="/basic">
                        <Item title="Basic Agency" content="Basic Agency is een van mijn favoriete full-service design agencies, de creatieve toepassing van hun website zit hem voornamelijk in de moderne vormgeving en in de keuze om verschillende stijlen voor verschillende onderwerpen te kiezen (culture manual, brandbeats etc). Ze maken gebruik van nieuwere webtechnieken zoals WebGL." image={imageOne}/>
                    </Link>
                    <Link to="/covermymeds">
                        <Item title="Covermymeds" content="Covermymeds is een geweldig voorbeeld voor een website met micro interacties. Veel elementen zijn klikbaar en geven een waardevolle toevoeging voor de gebruiker. De landingspagina geeft je het gevoel van een lange brochure wat extra wordt geaccentueerd door de side scroll." image={imageTwo}/>
                    </Link>
                </div>
                <div className={style.Row}>
                    <Link to="/wizardingworld">
                        <Item title="Wizarding World" content="Een website voor fans van het Harry Potter universum. Wat mooi is aan het ontwerp is dat het een oudere franchise (1997) weet te vertalen naar een hedendaags ontwerp wat nog steeds een jong publiek aantrekt." image={imageThree}/>
                    </Link>
                    <Link to="/simplychoco">
                        <Item title="Simply Chocolate" content="Een leuke manier van een webshop, door interactieve elementen toe te voegen en een verhaal te vertellen neemt deze webshop je geld (als je van chocola houdt) heel snel over. Gecombineerd met een modern ontwerp weten ze de doelgroep goed te bereiken." image={imageFour}/>
                    </Link>
                </div>
                <div className={style.Row}>
                    <div className={style.About}>
                        <p>Deze pagina dient als een mini case-study verzameling voor Brut Communicatie - Leroy Davidson Descelles</p>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Menu;