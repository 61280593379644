import React from 'react'
import Depth from '../Depth/Depth';
import image from '../../Assets/Images/1.jpg';
import imageTwo from '../../Assets/Images/Basic/1.JPG';
import imageThree from '../../Assets/Images/Basic/2.JPG';
import imageFour from '../../Assets/Images/Basic/3.JPG';
import style from '../Single.module.scss';
import { Link } from 'react-router-dom';

const Basic = () => {
    return (
        <Depth>
            <div className={style.IntroImage}>
                <img src={image} alt="introimage"/>
            </div>
            <div className={style.Intro}>
                <Link to="/">
                    Terug naar overzicht
                </Link>
                <h1>Basic Agency</h1>
                <p>Bekijk website op <a href="https://www.basicagency.com" target="_blank" rel="noopener noreferrer" >www.basicagency.com</a></p>
            </div>
            <div className={style.Why}>
                <h2>Samengevat</h2>
                <p>Basic Agency is een van mijn favoriete full-service design agencies, de creatieve toepassing van hun website zit hem voornamelijk in de moderne vormgeving en in de keuze om verschillende stijlen voor verschillende onderwerpen te kiezen (<a href="https://culture.basicagency.com/" target="_blank" rel="noopener noreferrer">culture manual</a>, <a href="https://brandbeats.basicagency.com/" target="_blank" rel="noopener noreferrer">brandbeats</a> etc). Ze maken gebruik van nieuwere webtechnieken zoals WebGL.</p>
            </div>
            <div class={style.Section}>
                <div class={style.Image} style={{backgroundImage: `url(${imageTwo})`}}></div>
                <div class={style.Text}>
                    <h2>Crafted</h2>
                    <p>Een special pagina voor evenementen die zijn georganiseerd door Basic. De preloader en laad animaties zijn zeer iconisch en mooi geïntegreerd. De scroll-lock bij bijvoorbeeld <a href="https://crafted.basicagency.com/interviews/mary-jhun" target="_blank" rel="noopener noreferrer">crafted.basicagency.com/interviews/mary-jhun</a> is een leuke toevoeging voor een anders erg saaie statische pagina die je vaak ziet bij een interview. </p>
                </div>
            </div>
            <div class={style.Section}>
                <div class={style.Text}>
                <h2><a href="https://culture.basicagency.com/" target="_blank" rel="noopener noreferrer">Culture manual</a></h2>
                    <p>Een nieuwe manier van een over ons pagina, het gebruik van WebGL voor de achtergrond en de dynamische content maakt dit echt een eye catcher. Met pakkende visuals en geïntegreerde video, vind ik deze manier van een bedrijf ontmoeten en de mensen erachter een stuk verfrissender dan een standaard kijk dit zijn de koppen achter het bedrijf. Echt de waarde van een bedrijf laten zien en de werkwijze i.p.v. oppervlakkige praat.</p>
                </div>
                <div class={style.Image} style={{backgroundImage: `url(${imageThree})`}}></div>
            </div>
            <div class={style.SectionFull}>
                <div class={style.ImageFull} style={{backgroundImage: `url(${imageFour})`}}></div>
                <div class={style.TextFull}>
                <h2>Back to basic</h2>
                    <p>Overal een zeer interactieve website waar vooral gebruik wordt gemaakt van wat meer experimentele technieken en uitingen waar toch weer een leuke toepassing in zit en waar velen hun inspiratie vanuit halen. Ze durven iets anders te doen en dat maakt de website vooral leuk.</p>
                </div>
            </div>
        </Depth>
    )
}

export default Basic;