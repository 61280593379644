import React from 'react';
import './App.scss';
import Overview from './Components/Overview/Overview';

function App() {
  return (
      <Overview />
  );
}

export default App;
