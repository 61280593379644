import React from 'react';
import Depth from '../Depth/Depth';
import image from '../../Assets/Images/4.jpg';
import imageTwo from '../../Assets/Images/Choco/1.JPG';
import imageFour from '../../Assets/Images/Choco/3.JPG';
import imageFive from '../../Assets/Images/Choco/4.JPG';
import style from '../Single.module.scss';
import { Link } from 'react-router-dom';

const Choco = () => {
    return (
        <>
        <Depth>
            <div className={style.IntroImage}>
                <img src={image} alt="introimage"/>
            </div>
            <div className={style.Intro}>
                <Link to="/">
                    Terug naar overzicht
                </Link>
                <h1>Simply Chocolate</h1>
                <p>Bekijk website op <a href="https://simplychocolate.dk/" target="_blank" rel="noopener noreferrer" >www.simplychocolate.dk</a></p>
            </div>
            <div className={style.Why}>
                <h2>Samengevat</h2>
                <p>Een leuke manier van een webshop, door interactieve elementen toe te voegen en een verhaal te vertellen neemt deze webshop je geld (als je van chocola houdt) heel snel over. Gecombineerd met een modern ontwerp weten ze de doelgroep goed te bereiken.</p>
            </div>
            <div class={style.Section}>
                <div class={style.Image} style={{backgroundImage: `url(${imageTwo})`}}></div>
                <div class={style.Text}>
                    <h2>Interactieve afbeeldingen</h2>
                    <p>Naast door het traditionele klikken kan je door de verpakking van de reep open te maken naar de desbetreffende product pagina gaan. Als je op de product pagina bent en je verder door het verhaal gaat komt er steeds meer zichtbaar van het product. Op deze manier wil je als gebruiker meer zien en blijf je scrollen tot je bij de call to action komt.</p>
                </div>
             
            </div>
            <div class={style.Section}>
                <div class={style.Text}>
                    <h2>Storytelling</h2>
                    <p>Wat ik vooral terug zie in zowel dit merk als andere succesvolle webshops is dat storytelling erg belangrijk is, voor zowel een oudere als jongere doelgroep wilt de klant meer investeren in een merk dan een product. Wat Simply Chocolate ook goed doet is dat het bij de kern van het product en merk blijft en niet te veel poes pas probeert te doen.</p>
                </div>
                <div class={style.Image} style={{backgroundImage: `url(${imageFive})`}}></div>
            </div>
            <div class={style.SectionFull}>
                <div class={style.ImageFull} style={{backgroundImage: `url(${imageFour})`}}></div>
            </div>
        </Depth>
        </>
    )
}

export default Choco;