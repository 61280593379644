import React from 'react';
import Basic from '../Pages/Basic';
import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";

import Menu from './Menu';
import CoverMyMeds from '../Pages/CoverMyMeds';
import Wizard from '../Pages/Wizard';
import Choco from '../Pages/Choco';

const Overview = () => {

    return (
        <>
        <Router>
  
            <Switch>
                <Route path="/basic">
                    <Basic />
                </Route>
                <Route path="/covermymeds">
                    <CoverMyMeds />
                </Route>
                <Route path="/wizardingworld">
                    <Wizard />
                </Route>
                <Route path="/simplychoco">
                    <Choco />
                </Route>

                <Route path="/">
                   <Menu />
                </Route>
            </Switch>
        </Router>
        </>        
    )
}

export default Overview;
