import React from 'react';
import Depth from '../Depth/Depth';
import image from '../../Assets/Images/3.jpg';
import imageTwo from '../../Assets/Images/Wizard/1.JPG';
import imageThree from '../../Assets/Images/Wizard/2.JPG';
import imageFour from '../../Assets/Images/Wizard/3.JPG';
import style from '../Single.module.scss';
import { Link } from 'react-router-dom';

const Wizard = () => {
    return (
        <>
            <Depth>
            <div className={style.IntroImage}>
                <img src={image} alt="introimage"/>
            </div>
            <div className={style.Intro}>
                <Link to="/">
                    Terug naar overzicht
                </Link>
                <h1>Wizarding World</h1>
                <p>Bekijk website op <a href="https://www.wizardingworld.com/" target="_blank" rel="noopener noreferrer" >www.wizardingworld.com</a></p>
            </div>
            <div className={style.Why}>
                <h2>Samengevat</h2>
                <p>Een website voor fans van het Harry Potter universum. Wat mooi is aan het ontwerp is dat het een oudere franchise (1997) weet te vertalen naar een hedendaags ontwerp wat nog steeds een jong publiek aantrekt.</p>
            </div>
            <div class={style.Section}>
                <div class={style.Text}>
                    <h2>Dicht bij het origineel</h2>
                    <p>De website oogt zeer modern en maakt gebruik van veel nieuwere technieken, van WebGL tot ReactJS. Je zou kunnen zeggen dat het.. ..magisch is <span role="img" aria-label="badum tss">🥁</span>. Alle gekheid op een toverstokje, de look en feel van de website oogt alsof het gemaakt kon worden in het universum zelf en toch is het modern. Het gebruik van de klassieke fonts, het iconische beeldmateriaal en de moderne navigatie maakt het een zeer mooie website / ervaring.</p>
                </div>
                <div class={style.Image} style={{backgroundImage: `url(${imageTwo})`}}></div>
            </div>
            <div class={style.SectionFull}>
                <div class={style.ImageFull} style={{backgroundImage: `url(${imageFour})`}}></div>
                <div class={style.TextFull}>
                <h2>Divers</h2>
                    <p>Van een pagina voor quizzen, een nieuws pagina tot een pagina voor de laatste uitgaven uit de serie. De website is een gigantische hub voor alles wat te maken heeft met de franchise en alle onderwerpen zijn een op zichzelf staand ontwerp.</p>
                </div>
            </div>
            <div class={style.Section}>
                <div class={style.Image} style={{backgroundImage: `url(${imageThree})`}}></div>
                <div class={style.Text}>
                    <h2>Meerdere "apps" binnen een website</h2>
                    <p>Op de website van Wizarding World zijn verschillende applicaties en/of games te vinden. Van het sorteren van je huis tot het maken van een toverstaf. Dit laat zien dat het niet allemaal eentonig hoeft te zijn, gezien dat meerdere applicaties een andere look en feel hebben die passen bij het onderwerp.</p>
                </div>
            </div>
        </Depth>
        </>
    )
};

export default Wizard;