import React from 'react'
import Depth from '../Depth/Depth';
import image from '../../Assets/Images/2.jpg';
import imageTwo from '../../Assets/Images/Meds/1.JPG';
import imageThree from '../../Assets/Images/Meds/2.JPG';
import imageFour from '../../Assets/Images/Meds/3.JPG';
import imageFive from '../../Assets/Images/Meds/4.JPG';
import style from '../Single.module.scss';
import { Link } from 'react-router-dom';

const CoverMyMeds = () => {
    return (
        <>
        <Depth>
            <div className={style.IntroImage}>
                <img src={image} alt="introimage"/>
            </div>
            <div className={style.Intro}>
                <Link to="/">
                    Terug naar overzicht
                </Link>
                <h1>Covermymeds</h1>
                <p>Bekijk website op <a href="https://experience.covermymeds.com/" target="_blank" rel="noopener noreferrer" >experience.covermymeds.com</a></p>
            </div>
            <div className={style.Why}>
                <h2>Samengevat</h2>
                <p>Covermymeds is een geweldig voorbeeld voor een website met micro interacties. Veel elementen zijn klikbaar en geven een waardevolle toevoeging voor de gebruiker. De landingspagina geeft je het gevoel van een lange brochure wat extra wordt geaccentueerd door de side scroll.</p>
            </div>
            <div class={style.Section}>
                <div class={style.Text}>
                    <h2>Parallax</h2>
                    <p>Door het gebruik van een parallax effect geeft het de website extra diepte. In combinatie met de knoppen die langzaam naar je cursor gaan als je in de buurt komt.</p>
                </div>
                <div class={style.Image} style={{backgroundImage: `url(${imageTwo})`}}></div>
            </div>
            <div class={style.Section}>
                <div class={style.Image} style={{backgroundImage: `url(${imageThree})`}}></div>
                <div class={style.Text}>
                    <h2>Micro interacties</h2>
                    <p>Over de hele pagina zijn interactieve elementen te vinden. Er zijn tientallen video's met hierin medewerkers die een stukje vertellen over het bedrijf wat in mijn ogen 10x meer zegt dan een saaie quote. De bezoeker wordt meegenomen met een verhaal, en storytelling is anno 2020 een van de sterkste marketing technieken.</p>
                </div>
            </div>
            <div class={style.SectionFull}>
                <div class={style.ImageFull} style={{backgroundImage: `url(${imageFour})`}}></div>
                <div class={style.TextFull}>
                <h2>Van horizontaal naar verticaal</h2>
                    <p>Het leuke aan dit ontwerp is ook de verandering van de verticale scroll naar horizontale. Het klinkt op papier als een nachtmerrie voor de gebruiker maar door de navigatie heel clean te houden is het heel goed te navigeren. Ondanks dat de elementen lijken te zweven zit er toch een structuur in doormiddel van een grid.</p>
                </div>
            </div>
            <div class={style.Section}>
                <div class={style.Text}>
                    <h2>Een multipaged website als "werken bij" pagina</h2>
                    <p>Door een toekomstige werknemer mee te nemen in het verhaal van het bedrijf maak je ze enthousiaster dan ooit om bij je te komen werken. Ze horen mensen die dezelfde denkwijze hebben en maken kennis met de core values van het bedrijf. Om vervolgens op de open posities te komen.</p>
                </div>
                <div class={style.Image} style={{backgroundImage: `url(${imageFive})`}}></div>
            </div>
        </Depth>
        </>
    )
}

export default CoverMyMeds;