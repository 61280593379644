import React, {useEffect} from 'react';
import style from '../Single.module.scss';
import { Link } from 'react-router-dom';

const Depth = (props) => {
    useEffect(() => {
        window.scrollTo({top:0, behavior: 'smooth'})
      }, [])

    return (
        <>
        <div className={style.Container}>
            <div className={style.Content}>
                {props.children}
            </div>
            <div className={style.Back}>
                <Link to="/">Terug naar overzicht</Link>
            </div>
        </div>
        </>
    )
};

export default Depth;